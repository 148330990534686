<template>
  <ribbon
    v-if="['admin', 'agent'].includes(siteUserRole)"
    class="has-background-warning"
    padding="none"
  >
    <div class="has-padding-100 has-text-centered">
      <p>
        <b-icon icon="shield-alt" size="is-small" /> You are viewing this site
        with <strong>{{ siteUserRole }}</strong> privileges –
        <router-link
          :to="{ path: `/admin/users/${site.authorId}` }"
          class="has-text-dark"
          ><u>view owner profile</u></router-link
        >.
      </p>
    </div>
  </ribbon>
</template>

<script>
export default {
  name: "SiteUserRole",
  props: {
    siteId: {
      type: String,
      required: true
    }
  },
  computed: {
    site() {
      return this.$store.getters["sites/site"](this.siteId);
    },
    siteUserRole() {
      return this.$store.getters["sites/site/userRole"](this.siteId);
    }
  }
};
</script>
